import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

const BannerVacancy = styled.section`
  position: relative;
  height: 100%;
  z-index: 1;
  min-height: 434px;
  margin-bottom: -5rem;
  &:before {
    content: '';
    height: 100%;
    min-height: 434px;
    width: 130%;
    position: absolute;
    z-index: -1;
    left: -15%;
    top: 0;
    @media (min-width: 991px) {
      border-radius: 0 0 50% 50%;
    }
    background: ${({ theme }) => theme.color.primary};
  }
`

const BannerWrapper = styled.div`
  position: relative;
  min-height: 434px;
  @media (max-width: 991px) {
    padding-bottom: 2.8rem;
  }
`

const Row = styled.div`
  padding-top: 9rem;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & p {
    color: ${({ theme }) => theme.color.light};
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 50px;
    margin-bottom: 2.5rem;
  }

  & p {
    font-size: 14px;
  }

  & span {
    color: ${({ theme }) => theme.color.secondaryDark};
    font-weight: bold;
  }

  @media (min-width: 992px) {
    padding-bottom: 6rem;
  }
`

const SvgWrapper = styled.div`
  min-width: 40px;
`

interface PageProps {
  data: {
    page: {
      title?: string
      vacancydetail: any
      path?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
}

const PageTemplate: React.FC<PageProps> = ({
  data: {
    page: { title, vacancydetail, flexContent, seo },
  },
  location = {},
  prefix = 'Vacancy_Flexcontent',
}) => (
  <Layout>
    <SEO seo={seo} />
    <BannerVacancy>
      <BannerWrapper>
        <div className="container h-100">
          <Row className="row justify-content-center align-items-end h-100">
            <div className="col-xl-8">
              <p>
                Vacatures <span>|</span> {title}
              </p>
              <h1 className="text-white text-uppercase">{title}</h1>
              <div className="d-flex flex-column flex-sm-row justify-content-between mx-md-4">
                {vacancydetail?.info.map((info: any, index: number) => (
                  <div
                    className="d-flex align-items-center mb-3 mb-sm-0"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <SvgWrapper>
                      <Plaatjie image={info.icons.iconblank} alt={info.title} />
                    </SvgWrapper>
                    <p className="mb-0">{info.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </Row>
        </div>
      </BannerWrapper>
    </BannerVacancy>
    <VacancyContext.Provider value={{ vacancyTitle: title || '' }}>
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
      />
    </VacancyContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query vacancyById($pageId: String!) {
    page: wpVacancy(id: { eq: $pageId }) {
      ...generalVacancyFragment
    }
  }
`

export interface VacancyContextProps {
  vacancyTitle: string
}

export const VacancyContext = React.createContext({
  vacancyTitle: '',
})

export default PageTemplate
