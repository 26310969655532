import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimaryBig'

// Images
import Upload from 'img/upload.svg'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import { VacancyContext } from 'templates/vacancy'

const Section = styled.section``

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
    max-width: 400px;
  }

  & .form-duuf-group-consent {
    @media (min-width: 576px) {
      margin-top: 7rem;
    }
    /* @media (max-width: 575px) {
      margin-top: 6rem;
    } */

    & a:hover {
      text-decoration: underline;
    }
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #e5e5e5;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file {
    position: relative;
    background-color: ${({ theme }) => theme.color.primary};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    background: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.light};
    max-width: 200px;
    &:before {
      content: url(${Upload});
      margin-right: 14px;
      margin-top: 5px;
    }
    /* &:after {
      content: 'Upload jouw cv';
    } */
    & input {
      position: relative;

      width: 200px;
      height: 100%;
      z-index: 999;
    }

    & button {
      position: relative;

      width: 160px;
      height: 100%;
      z-index: 999;
    }
  }

  .form-duuf-file-name {
    position: absolute;
    top: 60px;
    color: ${({ theme }) => theme.color.primary};
  }

  & .form-duuf-file-button {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 220px;
    height: 100%;

    & > button {
      color: ${({ theme }) => theme.color.light};
      padding-left: 20px;
      line-height: 18px;
    }
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-top: -0.56rem;
    margin-left: 0.5rem;
    margin-bottom: 0;
    line-height: 30px;
    @media (min-width: 768px) {
      padding-right: 6rem;
    }
  }

  & .form-duuf-checkbox {
    //margin-top: -2.5rem;
  }

  & .form-duuf-group-consent {
    & .form-duuf-checkbox {
      margin-right: 10px;
      margin-top: -24px;
    }

    & a {
      font-weight: ${({ theme }) => theme.font.weight.regular};
      line-height: 25px;
    }
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    position: relative;
    ${ButtonPrimaryCSS};

    &:after {
      position: absolute;
      bottom: -20px;
      left: 0;
      content: 'Binnen 24 uur reactie';
      font-weight: 400;
    }

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.font.size.mega};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    background: ${({ theme }) => theme.color.light};
    padding: 3rem;
    font-size: ${({ theme }) => theme.font.size.huge};
    width: 100%;
    margin: 0 auto;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const Content = styled(ParseContent)`
  & td > span {
    font-weight: ${({ theme }) => theme.font.weight.regular} !important;
    & a:hover {
      text-decoration: underline !important;
    }
  }
  & img {
    &:hover {
      opacity: 0.75;
    }
  }

  & a {
    color: ${({ theme }) => theme.color.primary} !important;
    text-decoration: none !important;
  }

  & .socials {
    margin-top: 0.75rem;
  }
`

interface FormJobApplicationProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormJobApplication: React.FC<FormJobApplicationProps> = ({ fields }) => {
  const context = React.useContext(VacancyContext)

  return (
    <Section id="apply">
      <div className="container py-5">
        <Content content={fields.description} className="mb-5" />
        <StyledFormDuuf
          id={6}
          generate={false}
          defaultFields={{ input_8: context.vacancyTitle }}
          privacyUrl="/privacyverklaring"
        >
          <div className="row">
            <div className="col-md-6 h-100">
              <FormDuufGroup hideIds={[7, 3]} showIds={[4, 1, 6]} />
            </div>
            <div
              className="col-md-6 h-100 d-flex flex-column align-items-between"
              style={{ minHeight: 282 }}
            >
              <div className="ms-md-4">
                <FormDuufGroup hideIds={[4, 1, 6]} showIds={[3]} />
                <FormDuufSubmit />
              </div>
            </div>
           
          </div>
        </StyledFormDuuf>
      </div>
    </Section>
  )
}

export default FormJobApplication
